.content {
    width: 120px;
}

.qr {
    display: block;
    margin: 3px auto;
    height: 100px;
    width: 100px;
}
