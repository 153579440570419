@import 'assets/styles/vars.scss';

.wrapper {
    width: 100%;
    height: 102px;
    padding: 21px 17px 0px;
    background-color: $color-white;
    text-transform: capitalize;

    :global {
        .ant-breadcrumb a:hover {
            color: $color-primary-light;
        }
    }
}
