$color-white: #ffffff;
$color-black: #000000;
$color-grey: #e6e6e6;
$color-grey-heavy: #898989;
$color-grey-heavier: #777777;
$color-purple-light: #f1f1fe;
$color-purple-lighter: #f2f1fe;
$color-purple-lightest: #fcfbff;
$color-grey-background: #f6f6fe;
$color-lightgrey-background: #f7faff;

$color-primary: #575be0;
$color-primary-dark: #3538a5;
$color-primary-darker: #22256c;
$color-primary-darkest: #333043;
$color-primary-light: #6c63ff;
$color-primary-lighter: #b5b6e3;
$color-primary-lightest: #d4d4f2;
$color-error: #ff7082;
$color-invalid: #ff4d4f;
$color-success: #0ac289;
$color-default: #0c0820;
$color-border: #b2b2b2;
$color-red: #e73a24;

$text-color-default: #322e43;
$text-color-alternate: #a19e97;
$text-color-price: #6c63ff;

$header-height: 50px;
$padding-base: 150px;
$padding-base-mobile: 15px;

$font-size-5xl: 3.75rem;
$font-size-4xl: 3rem;
$font-size-3xl: 1.875rem;
$font-size-2xl: 1.5rem;
$font-size-xl: 1.25rem;
$font-size-lg: 1.125rem;
$font-size-base: 1rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.75rem;
$font-size-2xs: 0.5rem;
$font-size-3xs: 0.375rem;
$font-size-4xs: 0.5rem;
$font-size-5xs: 0.325rem;

$font-size-title: 2rem;
$font-size-package-name: 35px;
$font-size-title-small: 30px;

$font-weight-bold: bold;
$font-weight-bolder: bolder;
$font-weight-normal: normal;
