@import 'assets/styles/vars.scss';

.footer {
    margin: 48px 0 24px 0;
    padding: 0 16px;
    text-align: center;
    background: #f8f8f8;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 72px;
    color: $text-color-default;
    font-size: 13px;

    .links {
        margin-bottom: 8px;

        a {
            color: $text-color-default;
            transition: all 0.3s;

            &:hover {
                color: #666;
            }
        }
    }
}
