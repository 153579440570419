@import 'assets/styles/vars.scss';

.header {
    padding: 0px !important;
    box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;
    justify-content: right;
    height: 72px;
    z-index: 9;
    align-items: center;
    background-color: $color-primary !important;

    .wrapper {
        padding-right: 20px;
        height: 72px;
        width: 100%;
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: right;
        justify-content: right;
        align-items: center;
        background-color: #fff;
        border-radius: 10px 0 0 0;
    }

    &.fixed {
        position: sticky;
        top: 0;
        right: 0;
        width: calc(100% + 1px);
        transition: width 0.2s;

        &.collapsed {
            width: calc(100% + 1px);
        }
    }

    :global {
        .ant-menu-submenu-title {
            height: 72px;
        }
    }

    .button {
        width: 72px;
        height: 72px;
        line-height: 72px;
        text-align: center;
        font-size: $font-size-base;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
            color: $color-primary;
            background-color: transparent;
        }
    }
}
