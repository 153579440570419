@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Avenir-Black';
    src: url('assets/fonts/AvenirLTStd-Black.woff');
}

@font-face {
    font-family: 'Avenir-Book';
    src: url('assets/fonts/AvenirLTStd-Book.woff');
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('assets/fonts/AvenirLTStd-Roman.woff');
}

@import 'assets/styles/vars.scss';

html,
body {
    font-family: 'Avenir-Roman', 'Avenir-Black', 'Avenir-Book', 'Nunito', sans-serif;
    font-size: 100%;
}

img {
    max-width: 100%;
}

.ant-form-item {
    margin-bottom: 0;
}

.ant-btn,
.ant-input {
    // height: 60px;
    border-radius: 4px;
}

.ant-btn-primary {
    background: $color-primary;
    border-color: $color-primary;
}

.ant-btn-primary:hover {
    background: $color-primary-light;
    border-color: $color-primary-light;
}

.ant-btn-primary:focus {
    background: $color-primary-light;
    border-color: $color-primary-light;
}

.ant-btn-primary:active {
    background: $color-primary-dark;
    border-color: $color-primary-dark;
}

.ant-carousel {
    .slick-slide {
        img {
            margin: 0 auto;
        }
    }
}

.modal-vertical-center {
    text-align: center;
    white-space: nowrap;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0;
    }

    .ant-modal {
        display: inline-block;
        vertical-align: middle;
        top: 0;
        text-align: left;
    }
}

.ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
        color: $color-white;
    }

    .ant-modal-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: $color-primary;

        .ant-modal-title {
            color: $color-white;
        }
    }
}

.modal-success {
    text-align: center;

    .title {
        color: $color-primary;
        font-size: $font-size-3xl;
        font-weight: $font-weight-bold;
    }

    .message {
        color: $text-color-alternate;
        font-size: $font-size-lg;
        font-weight: $font-weight-normal;
    }
}

.modal-error {
    text-align: center;

    .title {
        color: $color-error;
        font-size: $font-size-3xl;
        font-weight: $font-weight-bold;
    }

    .message {
        color: $text-color-alternate;
        font-size: $font-size-lg;
        font-weight: $font-weight-normal;
    }
}

.modal-success-header {
    text-align: center;

    &-icon {
        width: 70px;
    }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: $color-error;
    border-color: $color-error;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background: $color-grey-heavy;
    border-color: $color-grey-heavy;

    & > .ant-steps-icon {
        color: $color-white;
    }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    height: 5px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: $color-grey-heavy;
}

.ant-tag {
    border-radius: 10px;
    font-size: $font-size-sm;
}

.table-order-detail th.ant-table-cell {
    background: #f3f1ff;
}

.ant-scroll-number {
    right: -10px;
}

#topMenuMobile .ant-menu-horizontal .ant-menu-item,
#topMenuMobile .ant-menu-horizontal .ant-menu-submenu {
    padding: 0 10px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    font-size: $font-size-base;
}

.ant-menu-item a:hover .ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: $color-primary;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
    border-right: 3px solid $color-primary;
}

// admin

.admin-sider .ant-menu-root.ant-menu-inline {
    color: $color-white;
    background-color: transparent;
}

.admin-sider .ant-menu-item a {
    color: $color-white;
}

.admin-sider .ant-menu-item-selected a {
    color: $color-white;
}

.admin-sider .ant-menu-sub.ant-menu-inline {
    background-color: $color-primary-dark;
}

.admin-sider .ant-menu-submenu-selected {
    color: $color-white;
}
.admin-sider .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
    background-color: $color-primary-darker;
}

.admin-sider .ant-menu-item-selected {
    background-color: $color-primary-darker;
}

.ant-layout-sider.admin-sider {
    background-color: $color-primary;
}

.admin-menu {
    border-right: none;
    background-color: $color-primary;
    color: $color-white;

    .ant-menu-item:hover {
        color: $color-primary-lightest;
        a {
            color: $color-primary-lightest;
        }
    }
    .ant-menu-item:active {
        background-color: $color-primary-darker;
    }
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    .ant-menu-item-selected {
        color: $color-white;
    }
    .ant-menu-item-selected {
        background-color: $color-primary-darker !important;
    }
    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
        width: '100%';
    }
    .admin-sider .ant-menu-item-selected a:hover,
    .ant-menu-submenu-expand-icon:hover,
    .ant-menu-submenu-arrow:hover,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
    .ant-menu-submenu-title:hover {
        color: $color-primary-lightest;
    }
}

.tuihand-button-primary {
    background: $color-primary;
    border-color: $color-primary;
}

.tuihand-button-secondary {
    color: rgba(0, 0, 0, 0.65);
}

.tuihand-button-secondary:hover {
    color: $color-primary-light;
    border-color: $color-primary-light;
}
.tuihand-button-secondary:active,
.tuihand-button-secondary:focus {
    color: $color-primary;
    border-color: $color-primary;
}

.ant-tooltip-inner {
    background-color: $color-white;
    a {
        color: $color-primary;
    }
}
.ant-tooltip-arrow-content {
    background-color: $color-white;
}
