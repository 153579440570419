@import 'assets/styles/default.scss';

@mixin text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin text-gradient {
    background-image: -webkit-gradient(
        linear,
        37.219838% 34.532506%,
        36.425669% 93.178216%,
        from(#29cdff),
        to(#0a60ff),
        color-stop(0.37, #148eff)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin background-hover {
    transition: all 0.3s ease-out;
    &:hover {
        background-color: #f9f9fc;
    }
}

@mixin admin-checkbox {
    [class~='ant-checkbox-checked'] [class~='ant-checkbox-inner'] {
        background-color: $color-primary;
        border-color: $color-primary;
    }

    [class~='ant-checkbox-disabled'][class~='ant-checkbox-checked'] [class~='ant-checkbox-inner']::after {
        border-color: white;
    }

    [class~='ant-checkbox-indeterminate'] [class~='ant-checkbox-inner']::after {
        background-color: $color-primary;
    }

    [class~='ant-checkbox-checked']::after {
        border-color: $color-primary;
    }
}

@mixin user-checkbox {
    [class~='ant-checkbox'] + span {
        font-size: $font-size-lg;
    }

    [class~='ant-checkbox-wrapper'] + [class~='ant-checkbox-wrapper'] {
        margin-left: 0;
    }

    [class~='ant-checkbox-inner'] {
        width: 25px;
        height: 25px;
    }

    [class~='ant-checkbox-inner']::after {
        left: 31.5%;
    }

    [class~='ant-checkbox-checked'] [class~='ant-checkbox-inner'] {
        background-color: $color-error;
        border-color: $color-error;
    }

    [class~='ant-checkbox-checked']::after {
        border-color: $color-error;
    }
}
