@import 'assets/styles/vars.scss';

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-purple-light;
    background-color: $color-white !important;
    height: $header-height !important;
    line-height: $header-height !important;
    padding: 0 $padding-base !important;
    margin-top: 10px;

    &.mobile {
        flex-direction: column;
        align-items: center;
        padding: 0 !important;
        height: auto !important;

        .social {
            text-align: center;
        }

        .topMenu {
            // min-width: auto;
            width: 100%;
            justify-content: center;
        }
    }

    .social {
        width: auto;
        margin-right: 5px;
    }

    .social {
        .socialItem {
            width: 5px;
            color: $color-black;
            margin-right: 15px;
            padding-left: 10px;
        }
    }

    .topMenu {
        min-width: 555px;

        .topLink {
            color: $color-primary !important;
            font-weight: $font-weight-bold;
        }
    }
}

.navigator {
    background-color: $color-white !important;
    // height: $header-height * 2 !important;
    height: auto !important;
    width: 100% important;
    line-height: $header-height * 2 !important;
    padding: 0 $padding-base !important;
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr 1fr 1fr;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &.mobile {
        padding: 0 !important;
        flex-direction: column;

        .linkWrapper {
            .bottomMenu {
                min-width: auto;

                .navigatorLink {
                    font-size: $font-size-xs;
                }
            }
        }

        .pressReleaseWrapper {
            width: 100%;
            background-color: $color-white;
            text-align: center;
        }
    }

    .logo {
        width: 220px;
        height: auto;
    }

    .linkWrapper {
        display: flex;
        align-items: center;

        .bottomMenu {
            min-width: 480px;
            justify-content: space-between;

            .navigatorLink {
                font-size: $font-size-base;
            }
        }
    }

    .pressReleaseWrapper {
        .pressRelease {
            background: $color-primary;
            border-color: $color-primary;
        }
    }
}

.mobileLogo {
    display: none;
}

#languageSelect {
    margin-top: -5px;
    display: block;
}

#languageSelectMobile {
    display: none;
}

.socialWrapper {
    display: flex;
}

@media screen and (max-width: 1200px) {
    .top {
        display: flex;
        flex-direction: column;
        height: auto !important;
        align-items: center;
        padding: 0 !important;
    }

    .logo {
        width: 180px;
        height: auto;
    }

    .mobileLogo {
        display: block;
        padding: 10px;
    }

    .navigator {
        justify-content: center;
        display: flex;
    }

    .navigator .logo {
        display: none;
    }

    #languageSelect {
        display: none;
    }

    #languageSelectMobile {
        margin-top: -5px;
        display: block;
    }

    .topMenu {
        // min-width: auto;
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 640px) {
    .top {
        .topMenu {
            min-width: 360px;
        }
    }

    .navigator {
        .linkWrapper {
            .bottomMenu {
                min-width: 360px;
                .navigatorLink {
                    padding: 0 5px !important;
                }
            }
        }
    }
}
