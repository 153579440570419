@import 'assets/styles/vars.scss';

.layout {
    height: 100vh;

    .container {
        height: 100vh;
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .content {
        padding: 24px;
        background: #f8f8f8;
        min-height: calc(100% - 238px); // header 64px , footer 72px, breadcrumb, 102px
    }

    .backTop {
        right: 50px;
    }
}

@media (max-width: 767px) {
    .content {
        padding: 12px;
    }

    .backTop {
        right: 20px;
        bottom: 20px;
    }

    .container {
        height: 100vh;
        flex: 1;
        width: 100%;
    }
}
