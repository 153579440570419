@import 'assets/styles/vars.scss';

.footer {
    background-color: $color-default !important;

    &.mobile {
        .top {
            flex-direction: column;
            padding: $padding-base-mobile/2 $padding-base-mobile !important;

            .left,
            .center,
            .right {
                padding: 0;
            }
        }

        .bottom {
            padding: 0 $padding-base-mobile !important;
        }
    }

    .top {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: $padding-base/2 $padding-base !important;

        .left {
            // padding-right: $padding-base;
            // flex: 1;
            width: 50%;
            display: flex;
            flex-direction: column;
            color: $color-white;

            .logo {
                width: 220px;
                height: auto;
            }

            .startNow {
                background: $color-primary;
                border-color: $color-primary;
            }
        }

        .center {
            // padding: 0 $padding-base;
            // flex: 1;
            display: flex;
            width: 20%;
            flex-direction: column;
            color: $color-white;
        }

        .right {
            //  padding-left: $padding-base;
            //  flex: 1;
            display: flex;
            width: 20%;

            flex-direction: column;
            color: $color-white;

            .socialItem {
                color: $color-white;
                margin-right: 25px;
            }
        }

        .title {
            font-weight: $font-weight-bold;
        }

        .content {
            margin: 20px 0;
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-grey;
        padding: 0 $padding-base !important;

        .socialItem {
            color: $color-white;
            margin: 0 12px;
        }
    }
}

@media screen and (max-width: 960px) {
    .footer {
        .top {
            padding: 0 !important;
            flex-direction: column;

            .left {
                width: 100%;
            }
            .center {
                width: 100%;
            }
            .right {
                width: 100%;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $color-grey;
            padding: 0 !important;

            .socialItem {
                color: $color-white;
                margin: 0 12px;
            }
        }
    }
}
