@import 'assets/styles/vars.scss';

.brand {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;

    .spacer {
        height: 47px;
    }

    .name {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-size: $font-size-3xl;

        img {
            width: 36px;
            margin-right: 8px;
        }

        h1 {
            vertical-align: text-bottom;
            font-size: 16px;
            text-transform: uppercase;
            display: inline-block;
            font-weight: 700;
            color: red;
            white-space: nowrap;
            margin-bottom: 0;
            @include text-gradient();

            :local {
                animation: fadeRightIn 300ms ease-in-out;
                animation-fill-mode: both;
            }
        }
    }
}

.menu {
    :global {
        .ant-menu {
            background-color: $color-primary;
        }
        .ant-menu-vertical-left {
            border-right: none;
        }
        .ant-menu-item-selected,
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: $color-primary-darker;
        }
    }
}
